
export default {
  name: 'CreateOrder',
  props: {
    orderData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      open: true,
      active: {},
      producer: null
    }
  },
  computed: {
    page () {
      return this.$store.getters['sectionSchemas/findbyname']('orders')
    },
    schemas () {
      return this.page.schemas
    },
    currentProducer () {
      return this.$store.getters['contacts/find'](this.active.producer)
    },
    preventSubmit () {
      return this.isEmpty(this.active.run_date) ||
          this.isEmpty(this.active.run_time) ||
          this.isEmpty(this.active.diversionPoint) ||
          this.isEmpty(this.active.farmunit) ||
          this.isEmpty(this.active.flow) ||
          this.isEmpty(this.active.type)
    },
    formSchema () {
      const schema = _.cloneDeep(this.schemas.find(s => s.key === 'orders_form'))
      let headers = schema.headers

      headers = headers.map((header) => {
        if (this.objHas(this.orderData, 'isLocked') && this.orderData.isLocked) {
          if (header.options.propbindings.isLockable) {
            header.type = 'textBlock'
            return header
          } else if (header.options.propbindings.showWhenUnlocked) {
            return null
          }
        } else if (header.options.propbindings.hideWhenUnlocked) {
          return null
        }

        return header
      })
        .filter(item => item)

      if (this.isEmpty(this.active.diversionPoint)) {
        headers = headers.filter(h => !h.options.propbindings.isOrderDetail)
      }

      schema.headers = headers
      return schema
    }
  },
  watch: {
    'active.producer': {
      handler (n, o) {
        this.setActive()
        this.active.producer = n
        this.producer = this.currentProducer
      },
      immediate: true
    }
  },
  mounted () {
    this.setActive()
  },
  methods: {
    success () {
      this.$emit('closeOrderForm')
    },
    setActive () {
      this.active = { ...JSON.parse(JSON.stringify(this.$store.state.orders.bound)), ..._.cloneDeep(this.orderData) }
    },
    emitUp (emittedValue) {
      if (emittedValue.event) {
        this[emittedValue.event](emittedValue.value)
      }
    },
    showProducerTurnoutList (producerId) {
      console.log('showProducerTurnoutList', { producerId })
    },
    savedAndNowNew () {
      this.active = {
        ...JSON.parse(JSON.stringify(this.$store.state.orders.bound)),
        ...{
          producer: this.active.producer,
          diversionPoint: this.active.diversionPoint,
          farmunit: this.active.farmunit
        }
      }

      console.log({ active: this.active })
      this.open = true
    },
    dpSelected (dp) {
      this.active = {
        ...this.active,
        ...{ diversionPoint: dp.id, farmunit: dp.farmunit.id, ditch: dp.ditch.id, division: dp.division.id, completed: false }
      }
    },
    setFlow (flow) {
      this.active = {
        ...this.active,
        ...{ flow }
      }
    },
    dpRemoved () {
      this.active = {
        ...this.active,
        ...{ diversionPoint: '', farmunit: '', type: '', flow: '', ditch: '', division: '' }
      }
    },
    setType (type) {
      this.active = {
        ...this.active,
        ...{ type }
      }
    }

  }
}
