import { render, staticRenderFns } from "./EdgeConfig.vue?vue&type=template&id=095c865c&scoped=true&"
import script from "./EdgeConfig.vue?vue&type=script&lang=js&"
export * from "./EdgeConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095c865c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormElement: require('/opt/buildhome/repo/components/form/FormElement.vue').default})
