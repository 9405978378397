
import _ from 'lodash'
import formHandler from '@/mixins/formHandler'
export default {
  name: 'EdgeConfig',
  mixins: [formHandler],
  props: {
    schema: {
      type: Object,
      required: true
    },
    active: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      default: null
    },
    parent: {
      type: Object,
      default: null,
      required: false
    },
    parentId: {
      type: Number,
      default: null,
      required: false
    },
    preventSubmit: {
      type: Boolean,
      default: false
    },
    hideSubmit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      valid: false,
      editField: '',
      selectedField: '',
      loading: false,
      originalData: {}
    }
  },
  computed: {
    user () {
      return this.$store.getters['system/activeUser']
    },
    roles () {
      return this.$store.getters['system/roles']
    },
    is_ditchrider () {
      return this.roles.includes('ditchrider') || !this.is_admin
    },
    is_admin () {
      return this.roles.includes('admin') || this.roles.includes('superAdmin')
    },
    mutableHeaders () {
      const headers = JSON.parse(JSON.stringify(this.schema.headers))
      return headers.filter((header) => {
        if (Object.prototype.hasOwnProperty.call(header, 'condition')) {
          return this[header.condition.type](header.condition.field, header.condition.value)
        } else {
          return true
        }
      })
    },
    orderedHeaders () {
      const formconfig = this.schema.formconfig
      const mergedHeaders = this.mutableHeaders.map((header) => {
        const mergedBindings = { ...formconfig.options.propbindings, ...header.options.propbindings }
        header.options.propbindings = mergedBindings
        return header
      })

      return mergedHeaders.sort((a, b) => {
        return a.order - b.order
      })
    },
    formData: {
      get () {
        return this.active
      },
      set (value) {
        this.saveChangedField()
      }
    }
  },
  // watch: {
  //   active: {
  //     handler (newValue, oldValue) {
  //       if (newValue !== oldValue) {
  //         this.setFormData()
  //       }
  //     },
  //     deep: true
  //   }
  // },
  mounted () {
    this.setOriginalData()
  },
  methods: {
    divisionAllowed () {
      // This will only work if the user is a ditch rider

      const userDivisions = this.user.divisions

      // First we check if there is an input named division in form data
      if (
        this.objHas(this.formData, 'division') &&
          !this.isEmpty(this.formData.division) &&
          this.is_ditchrider &&
          !this.isEmpty(this.user.divisions)
      ) {
        if (Array.isArray(this.formData.division)) {
          return userDivisions.filter(ud => this.formData.division.includes(ud)).length > 0
        } else if (typeof this.formData.division === 'object') {
          return userDivisions.includes(this.formData.division.id)
        } else {
          return userDivisions.includes(this.formData.division)
        }
        // Else we check for the inputs which are related to a division
        // and search through those to see if they are in the user's divisions
      } else if (this.is_ditchrider && !this.isEmpty(this.user.divisions)) {
        const relationInputs = _.cloneDeep(this.schema.headers).filter(h => this.objHas(h, 'relatedModel'))

        const divisionRelatedInputs = relationInputs.filter(
          rh => _.cloneDeep(this.$store.state[rh.relatedModel].relations)
            .map(r => r.storename)
            .includes('divisions')
        )

        return divisionRelatedInputs.map((inp) => {
          const relatedValues = Array.isArray(this.formData[inp.field]) ? this.formData[inp.field] : [this.formData[inp.field]]

          return relatedValues.some((relatedValue) => {
            const item = this.$store.getters[`${inp.relatedModel}/find`](relatedValue)

            if (item && this.objHas(item, 'division')) {
              if (Array.isArray(item.division)) {
                const divIds = item.division.map(d => d.id)
                return _.intersection(divIds, userDivisions).length > 0
              } else if (typeof item.division === 'object') {
                return userDivisions.includes(item.division.id)
              }

              return userDivisions.includes(item.division)
            }
            return false
          })
        })
          .filter(item => item)
          .length > 0
      }

      return true
    },
    // submitForm checks validation and routes to the correct opperation
    submitForm () {
      if (this.$refs[this.schema.formconfig.key].validate()) {
        // preventSubmit stops all api calls from being made
        if (this.preventSubmit) {
          this.$emit('userHasSubmitted')
          return
        } else if (this.parentId) {
          this.subCreateOrUpdate()
          return
        }
        this.createOrUpdate()
      } else {
        this.$store.commit('snackbar/showMessage', {
          content: {
            status: 'Incomplete Form',
            message: 'Please fill out required fields'
          },
          color: 'warning'
        })
      }
    },

    submitAndNew () {
      if (this.$refs[this.schema.formconfig.key].validate()) {
        // preventSubmit stops all api calls from being made
        if (this.preventSubmit) {
          this.$emit('userHasSubmitted')
          return
        } else if (this.parentId) {
          this.subCreateOrUpdate()
          return
        }
        this.createOrUpdate('successAndNew')
      } else {
        this.$store.commit('snackbar/showMessage', {
          content: {
            status: 'Incomplete Form',
            message: 'Please fill out required fields'
          },
          color: 'warning'
        })
      }
    },

    emitUp (emits) {
      this.$emit('emitUp', emits)
    },

    //
    saveChangedField () {
      console.log(this.selectedField)
    },
    setOriginalData () {
      this.originalData = JSON.parse(JSON.stringify(this.active))
    },
    childSubmits (e) {
      e.stopPropagation()
      e.stopImmediatePropagation()
      e.target.blur()
      e.stopImmediatePropagation()
      if (!this.hideSubmit) {
        this.submitForm()
      }
    }

    //

  }
}
