import Vue from 'vue'
import { format } from 'date-fns'

export default {
  REMOVE (state, index) {
    console.log({ index })
    const all = JSON.parse(JSON.stringify(state.all))
    delete all[index]
    state.all = all
  },
  FLUSH_ACTIVE (state) {
    state.active = false
  },
  UPDATE_BOUND (state, { key, value }) {
    state.bound[key] = value
  },
  UPDATE (state, resource) {
    const index = state.all[resource.id]
    if (index === -1) {
      Vue.set(state.all, resource.id, resource)
    } else {
      const temp = { ...state.all[resource.id], ...resource }
      Vue.set(state.all, resource.id, temp)
    }
  },
  MERGE (state, resource) {
    const newState = JSON.parse(JSON.stringify(state.all))
    for (const r in resource) {
      // Get existing object from state, or create an empty object based on a stub
      const oldObj = state.all[r] || Object.assign({}, state.stub)
      // Merge the new data into the old object.
      const newObj = Object.assign({}, oldObj, resource[r])
      // Set new object in state.
      // Vue.set(state.all, r, newObj)
      newState[r] = newObj
    }
    state.all = newState
  },
  REPLACE_ALL (state, resource) {
    state.all = resource
  },
  SET_DYNAMIC (state, resource) {
    state[resource.key] = resource.value
  },
  updateBound (state, payload) {
    state.bound = payload
  },
  replaceFilter (state, data) {
    Vue.set(state, 'filter', data)
  },
  updateFilter (state, { key, value }) {
    const index = state.filter[key]
    if (index === -1) {
      Vue.set(state.filter, key, value)
    } else {
      const temp = { ...state.filter[key], ...value }
      Vue.set(state.filter, key, temp)
    }
  },
  SET_PRINT_PATH (state, path) {
    state.print_path = path
  },
  SET_FETCH_DATE (state, date) {
    state.latest_fetch = format(new Date(), 'YYYY-MM-DD HH:mm:ss')
  },

  updated (state) {
    state.isStale = false
  },

  isStale (state) {
    state.isStale = true
  }
}
