import { hasOwnProp } from '@/api/ApiHelpers'

export const state = () => ({
  snack: '',
  snackSettings: {
    color: 'secondary',
    top: false,
    timeout: 4000,
    right: true
  },
  history: [],
  activeView: false,
  loadStatus: 0,
  loadCount: 0,
  pastView: [],
  validateFields: {},
  showFormErrors: false,
  waterYear: null,
  availableWaterYears: [],
  customComponent: null,
  offlinePushRequests: [],
  cachedData: [],
  loadStores: [
    'sectionSchemas',
    'users',
    'syncHistory',
    'omsettings',
    'flags',
    'notes',
    'contacts',
    'divisions',
    'ditches',
    'diversionPoints',
    'states',
    'farmunits',
    'orders',
    'usages',
    'announcements',
    'offlineRequests'
  ]
})
export const actions = {
  async pushSync ({ commit, state, dispatch }) {
    const offlinePushRequests = state.offlinePushRequests
    if (offlinePushRequests.length > 0) {
      const pushSync = await this.$repositories.synchronizations.create({})
      if (hasOwnProp(pushSync, 'error')) {
        return
      }

      for (const req of offlinePushRequests) {
        if (!hasOwnProp(req, 'allowPush') || (hasOwnProp(req, 'allowPush') && req.allowPush === true)) {
          const result = await dispatch('sendRequest', { req, pushSync })
          console.log({ result })
        }
      }
      await dispatch('syncStats/show', 1, { root: true })
    }
  },

  async sendRequest ({ commit, state, dispatch }, { req, pushSync }) {
    let result = {}
    if (req.type === 'subCreate') {
      console.log('OFFLINE SUB-CREATE')
      console.log({ req })
      result = await this.$repositories[req.payload.type].subCreate(
        req.payload.id,
        req.payload.resource
      )
    } else if (req.type === 'create') {
      console.log('OFFLINE CREATE')
      console.log({ req })
      result = await this.$repositories[req.state].create(req.payload)
    } else if (req.type === 'update') {
      console.log('OFFLINE UPDATE')
      console.log({ req })
      result = await this.$repositories[req.state].update({
        id: req.payload.id,
        payload: req.payload.payload
      })
    } else if (req.type === 'delete') {
      console.log('OFFLINE DELETE')
      console.log({ req })
      result = await this.$repositories[req.state].delete(req.payload.id)
    }

    const logObject = { ...req }

    if (pushSync) {
      logObject.synchronization_id = pushSync.data.id
    }

    if (!result || result.isAxiosError || (result && hasOwnProp(result.response, 'data') && hasOwnProp(result.response.data, 'errors'))) {
      logObject.status = 'error'
      logObject.errorData = result?.response?.data?.errors ? Object.values(result.response.data.errors).join(', ') : result.response.data.message

      const errMessage = result?.response?.data?.errors
        ? Object.values(result.response.data.errors).join(', ')
        : `Please Contact Support.${logObject.synchronization_id ? (' Error ID: ' + logObject.synchronization_id) : ''}`

      await commit('UPDATE_OFFLINE_REQUEST', {
        ...req,
        status: 'Error',
        error_data: errMessage
      })

      dispatch('snackbar/showMessage', {
        content: {
          message: errMessage,
          status: result.response.status
        },
        color: 'error'
      }, {
        root: true
      })
    } else {
      if (['create', 'subCreate'].includes(req.type)) {
        logObject.created_model_id = result.data.id
      }
      await commit('REMOVE_OFFLINE_REQUEST', req.requestId)
      await commit(req.state + '/REMOVE', req.requestId, { root: true })
      logObject.status = 'success'
    }
    await this.$repositories.offlineRequests.create(logObject)

    return result
  },

  resetPassword ({ commit, state }, payload) {
    return this.$repositories.forgotPassword.create(payload)
  },
  async navBack ({ commit, state }) {
    const newActive = state.pastView[1]
    await commit('NAVIGATE_ACTIVE_DIALOG_BACK')
    return newActive
  },
  setWaterYear ({ commit, dispatch, rootState }, value) {
    // Set system year value
    commit('SET_WATER_YEAR', value)
    const om = Object.values(rootState.omsettings.all).find(
      om => parseInt(om.year) === parseInt(value)
    )

    // Object to use in filters based on selected year
    const yearObject = {
      start: om ? om.water_year_start : `${value}-01-01`,
      end: om ? om.water_year_end : `${value}-12-31`
    }

    // Loop through all stores available in vuex and set filters accordingly
    for (const [k, v] of Object.entries(rootState)) {
      const existingFilter = v.filter
      const newFilter = {}

      // Find fields of the store object effected by year and add that to filter
      if (hasOwnProp(v, 'effectedByYear') && v.effectedByYear) {
        if (hasOwnProp(v, 'yearFields') && v.yearFields.length > 0) {
          const attrBetweenDate = {}
          for (const item of v.yearFields) {
            attrBetweenDate[item] = yearObject
          }
          newFilter.attr_between_date = attrBetweenDate
        }

        // Find relations of the store object and add their fields effected by year to filter
        if (hasOwnProp(v, 'relations') && v.relations.length > 0) {
          const obj = {}

          for (const item of Object.values(v.relations)) {
            if (
              hasOwnProp(item, 'yearFields') &&
                            item.yearFields.length > 0
            ) {
              const items = {
                attr_between_date: {}
              }
              for (const el of item.yearFields) {
                items.attr_between_date[el] = yearObject
              }
              obj[item.translate] = items
            }
          }

          if (Object.values(obj).length > 0) {
            newFilter.relations = obj
          }
        }

        commit(
                    `${k}/replaceFilter`,
                    { ...existingFilter, ...newFilter },
                    { root: true }
        )
      }
    }
  }
}
export const mutations = {
  handle_error (state, snack) {
    state.snack = snack
  },
  set_validateFields: (state, validateFields) => {
    state.validateFields = validateFields
  },
  set_showFormErrors: (state, showFormErrors) => {
    state.showFormErrors = showFormErrors
  },
  handle_errorSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings
  },
  NAVIGATE_ACTIVE_DIALOG_BACK (state) {
    state.pastView.splice(0, 2)
  },
  pushHistory (state, route) {
    state.history.push(route)
  },
  popHistory (state) {
    state.history.pop()
  },
  SET_HISTORY (state, payload) {
    state.pastView.unshift(payload)
  },
  SET_LOADCOUNT (state, payload) {
    state.loadCount = payload
  },
  SET_LOADSTATUS (state, payload) {
    state.loadStatus = payload
  },
  FLUSH_HISTORY (state) {
    state.pastView = []
    state.history = []
  },
  SET_DYNAMIC (state, resource) {
    state[resource.key] = resource.value
  },

  SET_CUSTOM_COMPONENT (state, componentName) {
    state.customComponent = componentName
  },

  SET_ACTIVE_VIEW (state, active) {
    state.activeView = active
  },

  SET_SNACK (state, snack) {
    state.snack = snack
  },

  set_snackSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings
  },

  SET_ALL_WATER_YEARS (state, payload) {
    state.availableWaterYears = Object.values(payload)
  },

  SET_WATER_YEAR (state, payload) {
    state.waterYear = payload
  },

  PUT_OFFLINE_REQUEST (state, payload) {
    const offlinePushRequests = state.offlinePushRequests
    offlinePushRequests.push(payload)
    state.offlinePushRequests = offlinePushRequests
  },

  REPLACE_ALL_OFFLINE_REQUESTS (state, payload) {
    state.offlinePushRequests = payload
  },

  UPDATE_OFFLINE_REQUEST (state, payload) {
    const offlinePushRequests = state.offlinePushRequests
    const item = offlinePushRequests.findIndex(
      i => i.requestId === payload.requestId
    )
    offlinePushRequests.splice(item, 1, payload)
    state.offlinePushRequests = offlinePushRequests
  },

  REMOVE_OFFLINE_REQUEST (state, requestId) {
    state.offlinePushRequests = state.offlinePushRequests.filter(
      or => or.requestId !== requestId
    )
  }
}
export const getters = {
  permissions (state, commit, rootState) {
    let permissions = []
    permissions = rootState.auth.user.data.can
    return permissions
  },
  activeUser (state, commit, rootState) {
    let user = {}
    if (rootState.auth.user.data) {
      user = rootState.auth.user.data
    }

    return user
  },
  roles (state, commit, rootState) {
    let roles = []
    if (rootState.auth.user.data) {
      roles = rootState.auth.user.data.roles
    }
    return roles
  },
  getWaterYear (state) {
    return state.waterYear
  },
  getAllWaterYears (state) {
    return state.availableWaterYears
  },
  getLatestWaterYear (state) {
    const newOrder = JSON.parse(JSON.stringify(state.availableWaterYears))
    newOrder.sort()
    newOrder.reverse()
    return newOrder[0]
  },
  offlineRequests (state) {
    return state.offlinePushRequests
  },
  getPastView (state) {
    return _.cloneDeep(state.pastView)
  }
}
