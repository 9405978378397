import createRepository from '~/api/Repository.js'

export default (ctx, inject) => {
  const repositoryWithAxios = createRepository(ctx.$axios)

  const repositories = {
    states: repositoryWithAxios('states'),
    cities: repositoryWithAxios('cities'),
    getWaterYears: repositoryWithAxios('getWaterYears'),
    forgotPassword: repositoryWithAxios('forgotPassword'),
    synchronizations: repositoryWithAxios('synchronizations'),
    syncStats: repositoryWithAxios('syncStats'),
    syncHistory: repositoryWithAxios('syncHistory'),
    announcements: repositoryWithAxios('announcements'),
    // memberHeaders: repositoryWithAxios('memberHeaders'),
    historicalOrders: repositoryWithAxios('historicalOrders'),

    offlineRequests: repositoryWithAxios('offlineRequests'),

    filters: repositoryWithAxios('filters'),
    sectionSchemas: repositoryWithAxios('sectionSchemas'),

    farmunits: repositoryWithAxios('farmunits'),
    farmunitOwners: repositoryWithAxios('farmunits', 'owners'),
    farmunitUsages: repositoryWithAxios('farmunits', 'usages'),
    farmunitProducers: repositoryWithAxios('farmunits', 'producers'),
    farmunitDiversionPoints: repositoryWithAxios('farmunits', 'diversionPoints'),
    farmunitsFlags: repositoryWithAxios('farmunits', 'flags'),

    divisions: repositoryWithAxios('divisions'),
    divisionDiversionPoints: repositoryWithAxios('divisions', 'diversionPoints'),

    // districts: repositoryWithAxios("districts"),
    ditches: repositoryWithAxios('ditches'),
    orders: repositoryWithAxios('orders'),
    usages: repositoryWithAxios('usages'),

    flags: repositoryWithAxios('flags'),
    flagContacts: repositoryWithAxios('flags', 'contacts'),
    flagFarmunits: repositoryWithAxios('flags', 'farmunits'),

    contacts: repositoryWithAxios('contacts'),
    contactNotes: repositoryWithAxios('contacts', 'notes'),
    contactOwns: repositoryWithAxios('contacts', 'owns'),
    contactProduces: repositoryWithAxios('contacts', 'produces'),
    contactDiversionPoints: repositoryWithAxios('contacts', 'diversionPoints'),
    contactsFlags: repositoryWithAxios('contacts', 'flags'),

    producerOrders: repositoryWithAxios('producers', 'orders'),
    producerUsages: repositoryWithAxios('producers', 'usages'),

    diversionPointOrders: repositoryWithAxios('diversionPoints', 'orders'),
    diversionPointUsages: repositoryWithAxios('diversionPoints', 'usages'),
    diversionPoints: repositoryWithAxios('diversionPoints'),

    users: repositoryWithAxios('users'),
    notes: repositoryWithAxios('notes'),
    omsettings: repositoryWithAxios('omsettings'),
    uploadContacts: repositoryWithAxios('uploadContacts'),
    uploadFarmunits: repositoryWithAxios('uploadFarmunits'),
    uploadDitches: repositoryWithAxios('uploadDitches'),
    uploadDiversionpoints: repositoryWithAxios('uploadDiversionpoints'),
    userNotifications: repositoryWithAxios('users', 'notifications'),
    // filters: repositoryWithAxios('filters'),

    logo: repositoryWithAxios('logo'),

    // reports
    farmunitReports: repositoryWithAxios('farmunits', 'reports'),
    contactReports: repositoryWithAxios('contactReports'),
    // ditchRiderLogReport: repositoryWithAxios("ditchRiderLogReport"),
    divisionUsage: repositoryWithAxios('divisionUsage'),
    orderReports: repositoryWithAxios('orderReports'),
    reportProducerUsage: repositoryWithAxios('reportProducerUsage'),
    reportProducerDetailUsage: repositoryWithAxios('reportProducerDetailUsage'),
    reportDitchriderDirectory: repositoryWithAxios('reportDitchriderDirectory'),
    ditchRiderLogReport: repositoryWithAxios('ditchRiderLogReport'),
    reportDitchRiderDaily: repositoryWithAxios('reportDitchRiderDaily'),
    reportProducerFlags: repositoryWithAxios('reportProducerFlags'),
    reportUsageCards: repositoryWithAxios('reportUsageCards'),
    reportCountytaxes: repositoryWithAxios('reportCountytaxes'),
    reportOwnerCertification: repositoryWithAxios('reportOwnerCertification'),
    reportProducerCertification: repositoryWithAxios('reportProducerCertification'),
    irrigationPlan: repositoryWithAxios('irrigationPlan'),
    reportProducerMailingLabels: repositoryWithAxios('reportProducerMailingLabels'),
    reportOwnerMailingLabels: repositoryWithAxios('reportOwnerMailingLabels'),
    reportRationLog: repositoryWithAxios('reportRationLog'),
    reportDistrictVoters: repositoryWithAxios('reportDistrictVoters'),
    reportMapExport: repositoryWithAxios('reportMapExport')
  }

  inject('repositories', repositories)
}
