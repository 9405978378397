export default {
  methods: {
    async createOrUpdate (emitEvent = 'success') {
      this.$nuxt.$loading.start()
      if (this.formData.id) {
        const dirtyData = this.onlyDirty(this.formData)
        const obj = {
          id: this.formData.id,
          payload: dirtyData
        }
        const results = await this.$store.dispatch(`${this.schema.formconfig.model}/update`, obj)
        if (results && !results.isAxiosError && !this.objHas(results, 'error') && !results?.response?.data?.errors) {
          this.$emit(emitEvent, { ...results })
        }
        this.$nuxt.$loading.finish()
      } else {
        const cleanData = this.cleanResource(this.formData)
        const results = await this.$store.dispatch(
        `${this.schema.formconfig.model}/create`,
        cleanData
        )
        if (results && !results.isAxiosError && !this.objHas(results, 'error') && !results?.response?.data?.errors) {
          this.$emit(emitEvent, () => this.$refs[this.schema.formconfig.key].reset())
        }
        this.$nuxt.$loading.finish()
      }
    },
    async subCreateOrUpdate () {
      this.$nuxt.$loading.start()
      if (this.formData.subId) {
        const onlyDirty = this.onlyDirty(this.formData)
        const payload = {
          parentId: this.parentId,
          subId: this.formData.subId,
          repositoryPath: this.schema.repositoryPath,
          subModel: this.schema.model,
          onlyDirty
        }

        const results = await this.$store.dispatch(
                `${this.schema.returns}/subUpdate`,
                payload
        )
        if (results && !results.isAxiosError && !this.objHas(results, 'error') && !results?.response?.data?.errors) {
          this.$emit('success', { ...results.data })
        }
        this.$nuxt.$loading.finish()
      } else {
        const cleanData = this.cleanResource(this.formData)
        const payload = {
          id: this.parentId,
          repositoryPath: this.schema.repositoryPath,
          subModel: this.schema.model,
          cleanData
        }
        const results = await this.$store.dispatch(
                `${this.schema.returns}/subCreate`,
                payload
        )

        if (results && !results.isAxiosError && !this.objHas(results, 'error') && !results?.response?.data?.errors) {
          this.$emit('success', { ...results.data })
        }
        this.$nuxt.$loading.finish()
      }

      // handle create/update for sub
    },
    cleanResource (data) {
      const resource = JSON.parse(JSON.stringify(data))
      for (const property in data) {
        if (data[property] === '') {
          // don't submit empty strings to the api
          delete resource[property]
        }
      }
      return resource
    },
    onlyDirty (data) {
      const resource = JSON.parse(JSON.stringify(data))
      for (const property in data) {
        if (_.isEqual(data[property], this.originalData[property])) {
          delete resource[property]
        }
      }
      return resource
    },
    showIfLengthGreaterThan (field, value) {
      return false
      // return this.formData[field].length > value
    }
  }
}
