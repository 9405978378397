import { normalize } from 'normalizr'
import localForage from 'localforage'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import { hasOwnProp } from './ApiHelpers'

export default {
  async list ({
    commit,
    state,
    getters,
    dispatch
  }) {
    if (!navigator.onLine) {
      return
    }

    const params = getters.params

    // Check if repository for the current state type exists
    if (!this.$repositories[state.type]) {
      console.error(`No repository for type: ${state.type}`)
      return
    }

    const response = await this.$repositories[state.type].index(params)

    if (response) {
      const { entities } = normalize(response.data, [
        this.$relationships[state.type]
      ])
      commit('MERGE', entities[state.type])
      commit('SET_FETCH_DATE')
    }
  },

  async subList ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payload) {
    if (!navigator.onLine) {
      return
    }

    const response = await this.$repositories[payload.type].subIndex(payload.id)

    dispatch(`${payload.child}/subListMerge`, response.data, {
      root: true
    })
  },
  subListMerge ({
    commit,
    state,
    getters,
    dispatch
  }, payload) {
    const { entities } = normalize(payload, [this.$relationships[state.type]])
    commit('MERGE', entities[state.type])
  },

  merge ({
    commit,
    state,
    dispatch
  }, payload) {
    commit('MERGE', payload)
  },

  async create ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payload) {
    let results = {}

    payload.waterYear = rootState.system.waterYear

    if (navigator.onLine) {
      results = await this.$repositories[state.type].create(payload)
      for (const relation of state.relations) {
        dispatch(`${relation.storename}/list`, null, { root: true })
      }
    } else {
      const requestId = uuidv4()
      const obj = {
        type: 'create',
        state: state.type,
        requestId,
        payload,
        requestDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')
      }

      payload.user = rootState.auth.user.data.id

      const headers = getters.headers.map(i => i.field)

      const updatedResource = {}
      headers.forEach((i) => {
        if (hasOwnProp(payload, i)) {
          updatedResource[i] = payload[i]
        } else {
          updatedResource[i] = ''
        }
      })

      await commit('system/PUT_OFFLINE_REQUEST', obj, { root: true })

      dispatch('snackbar/showMessage', {
        content: {
          message: 'Your request is added to the synchronization queue. Do not forget to sync your requests when you\'re back online!',
          status: 200
        },
        color: 'success'
      }, {
        root: true
      })

      results = { data: { id: requestId, ...updatedResource } }
    }

    if (results && !results.isAxiosError && !hasOwnProp(results, 'error') && !results?.response?.data?.errors) {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit('MERGE', entities[state.type])

      if (navigator.onLine) {
        dispatch('snackbar/showMessage', {
          content: {
            message: 'Success!',
            status: 201
          },
          color: 'success'
        }, {
          root: true
        })
      }

      return results
    }

    return results
  },

  async subCreate ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payload) {
    const repositoryPath = payload.repositoryPath
    const id = payload.id
    const resource = payload.cleanData
    const subModel = payload.subModel
    resource.waterYear = rootState.system.waterYear

    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[repositoryPath].subCreate(id, resource)
      for (const relation of state.relations) {
        dispatch(`${relation.storename}/list`, null, { root: true })
      }
    } else {
      console.log('OFFLINE, SUBCREATE')
      console.log({ payload })
      if (payload.parentType) {
        resource[payload.parentType] = id
      }
      resource.user = rootState.auth.user.data.id

      const headers = getters.headers.map(i => i.field)

      const updatedResource = {}
      headers.forEach((i) => {
        if (hasOwnProp(resource, i)) {
          updatedResource[i] = resource[i]
        } else {
          updatedResource[i] = ''
        }
      })

      const requestId = uuidv4()
      const obj = {
        type: 'subCreate',
        state: state.type,
        requestId,
        payload: { ...payload, resource },
        requestDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')
      }

      await commit('system/PUT_OFFLINE_REQUEST', obj, { root: true })

      dispatch('snackbar/showMessage', {
        content: {
          message: 'Your request is added to the synchronization queue. Do not forget to sync your requests when you\'re back online!',
          status: 200
        },
        color: 'success'
      }, {
        root: true
      })

      results = { data: { id: requestId, ...updatedResource } }
    }

    if (!results || results.isAxiosError || hasOwnProp(results, 'error') || results?.response?.data?.errors) {
      return results
    }

    const { entities } = normalize(
      [results.data],
      [this.$relationships[subModel]]
    )

    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )

    // dispatch(`${subModel}/merge`, entities[subModel], { root: true })

    return results
  },

  async show ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, id) {
    // Todo:: check here later
    if (!navigator.onLine) {
      commit('active', id)
      return
    }

    // This is needed to include relations
    const params = getters.showParams

    const results = await this.$repositories[state.type].show(id, params)

    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    commit('MERGE', entities[state.type])
  },
  async update (
    {
      commit,
      state,
      getters,
      dispatch,
      rootState
    },
    {
      id,
      payload
    }
  ) {
    payload.waterYear = rootState.system.waterYear

    let results = {}

    // IF ITEM BEING EDITED IS HASN'T BEEN PUSHED CHECK AND ONLY UPDATE LOCALLY
    let itemCreateOffline = rootState.system.offlinePushRequests.find((i) => {
      return (
        (i.type === 'subCreate' || i.type === 'create') && i.requestId === id
      )
    })
    if (itemCreateOffline) {
      itemCreateOffline = JSON.parse(JSON.stringify(itemCreateOffline))
      Object.entries(payload).forEach(([key, value]) => {
        if (itemCreateOffline.type === 'subCreate') {
          itemCreateOffline.payload.cleanData[key] = value
        } else if (itemCreateOffline.type === 'create') {
          itemCreateOffline.payload[key] = value
        }
      })

      await commit('system/UPDATE_OFFLINE_REQUEST', itemCreateOffline, {
        root: true
      })
      let newPayload = { id }
      if (itemCreateOffline.type === 'subCreate') {
        newPayload = { ...newPayload, ...itemCreateOffline.payload.cleanData }
      } else if (itemCreateOffline.type === 'create') {
        newPayload = { ...newPayload, ...itemCreateOffline.payload }
      }
      results = { data: { ...newPayload } }
    } else if (navigator.onLine) {
      results = await this.$repositories[state.type].update({
        id,
        payload
      })
      for (const relation of state.relations) {
        dispatch(`${relation.storename}/list`, null, { root: true })
      }
    } else {
      console.log('OFFLINE, UPDATE')
      console.log({ payload })
      const requestId = uuidv4()
      const obj = {
        type: 'update',
        state: state.type,
        requestId,
        payload: { id, payload },
        requestDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')
      }
      await commit('system/PUT_OFFLINE_REQUEST', obj, { root: true })

      dispatch('snackbar/showMessage', {
        content: {
          message: 'Your request is added to the synchronization queue. Do not forget to sync your requests when you\'re back online!',
          status: 200
        },
        color: 'success'
      }, {
        root: true
      })
      payload.id = id
      results = { data: { ...payload } }
    }

    if (!results || results.isAxiosError || hasOwnProp(results, 'error') || results?.response?.data?.errors) {
      return results
    }

    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    console.log('UPDATE')
    console.log({ entities })
    //      commit("MERGE", entities[state.type]);
    // Object.entries(entities).forEach(([key, value]) =>
    //   dispatch(`${key}/merge`, value, {
    //     root: true
    //   })
    // )

    commit('MERGE', entities[state.type])
    dispatch('snackbar/showMessage', {
      content: {
        message: 'Success!',
        status: 200
      },
      color: 'success'
    }, {
      root: true
    })
    return true
  },
  UpdateBound ({
    commit,
    state
  }, payload) {
    const schema = Object.values(payload)
    const defaultBound = state.bound
    const p = {}
    schema.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (child.type === 'checkbox') {
            p[child.field] = defaultBound[child.field] ? defaultBound[child.field] : 0
          } else {
            p[child.field] = defaultBound[child.field] ? defaultBound[child.field] : ''
          }
        })
      } else if (item.type === 'checkbox') {
        p[item.field] = defaultBound[item.field] ? defaultBound[item.field] : 0
      } else {
        p[item.field] = defaultBound[item.field] ? defaultBound[item.field] : ''
      }
    })
    commit('updateBound', p)
  },

  async subUpdate (
    { commit, state, getters, dispatch, rootState },
    { parentId, subId, repositoryPath, onlyDirty }
  ) {
    const resource = { ...onlyDirty }
    resource.waterYear = rootState.system.waterYear
    let results

    if (navigator.onLine) {
      results = await this.$repositories[repositoryPath].subUpdate(
        parentId,
        subId,
        resource
      )
    } else {
      console.log('OFFLINE, CREATE')
      console.log({ onlyDirty })
    }

    if (!results || results.isAxiosError || hasOwnProp(results, 'error') || results?.response?.data?.errors) {
      return results
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit('MERGE', entities[state.type])
      dispatch('snackbar/showMessage', {
        content: {
          message: 'Success!',
          status: 200
        },
        color: 'success'
      }, {
        root: true
      })
      return true
    }
  },

  async delete ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payloadItem) {
    let results = {}
    const id = payloadItem.id
    if (navigator.onLine) {
      results = await this.$repositories[state.type].delete(id)
      for (const relation of state.relations) {
        dispatch(`${relation.storename}/list`, null, { root: true })
      }
    } else {
      const requestId = uuidv4()
      const obj = {
        type: 'delete',
        state: state.type,
        requestId,
        payload: { id, payload: { ...payloadItem } },
        requestDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')
      }

      await commit('system/PUT_OFFLINE_REQUEST', obj, { root: true })

      dispatch('snackbar/showMessage', {
        content: {
          message: 'Your request is added to the synchronization queue. Do not forget to sync your requests when you\'re back online!',
          status: 200
        },
        color: 'success'
      }, {
        root: true
      })

      results = { data: { id: requestId } }
    }

    if (results && !results.isAxiosError && !hasOwnProp(results, 'error') && !results?.response?.data?.errors) {
      commit('FLUSH_ACTIVE')
      commit('REMOVE', id)

      if (navigator.onLine) {
        dispatch('snackbar/showMessage', {
          content: {
            message: 'Deleted successfully!',
            status: 201
          },
          color: 'success'
        }, {
          root: true
        })
      }

      return results
    }

    return results
  },

  async detach (
    {
      commit,
      state,
      getters,
      dispatch
    },
    {
      parentId,
      childId,
      path,
      apiPath
    }
  ) {
    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[apiPath].subDelete(parentId, childId)
    } else {
      const requestId = uuidv4()
      const obj = {
        type: 'detach',
        state: state.type,
        requestId,
        payload: {
          parentId,
          childId,
          path,
          apiPath
        },
        requestDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')
      }
      await commit('system/PUT_OFFLINE_REQUEST', obj, { root: true })
      dispatch('snackbar/showMessage', {
        content: {
          message: 'Your request is added to the synchronization queue. Do not forget to sync your requests when you\'re back online!',
          status: 200
        },
        color: 'success'
      }, {
        root: true
      })
    }

    if (!results || results.isAxiosError || hasOwnProp(results, 'error') || results?.response?.data?.errors) {
      return results
    }

    // returns parent
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    // commit("MERGE", entities[state.type]);
    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )
  },
  resetFilter ({ commit, state, getters, dispatch }, filter) {
    commit('RESET_FILTER')
  },

  filter ({ commit, state, getters, dispatch }, filter) {
    commit('updateFilter', filter)
    dispatch('list')
  },
  async print ({ commit, state, getters, dispatch }, printedColumns) {
    const x = getters.params
    x.print = true
    x.export = false
    x.printedColumns = printedColumns
    const results = await this.$repositories[state.type].print(x)
    if (hasOwnProp(results, 'error')) {
      dispatch('set_snack', results)
    } else {
      commit('SET_PRINT_PATH', results.data.path)
    }
  },
  async postPrint ({ commit, state, getters, dispatch }, payload) {
    console.log({ payload })
    payload.print = true
    payload.export = false
    payload.route = `${state.type}.index`
    const results = await this.$repositories[state.type].postPrint(payload)
    commit('SET_PRINT_PATH', results.data.path)
  },
  async exportMerge ({ commit, state, getters }, payload) {
    payload.print = false
    payload.export = true
    payload.route = `${state.type}.index`

    const results = await this.$repositories[state.type].postPrint(payload)
    if (hasOwnProp(results, 'error')) {
      return
    }

    commit('SET_PRINT_PATH', results.data.path)
  },
  async export ({ commit, state, getters }, payload) {
    payload.print = false
    payload.export = true
    payload.exporter_model = 'App\\Exports\\RepeatExport'
    payload.route = `${state.type}.index`

    const results = await this.$repositories[state.type].postPrint(payload)
    if (hasOwnProp(results, 'error')) {
      return
    }

    commit('SET_PRINT_PATH', results.data.path)
  },

  async subListPrint ({ commit, state, getters, dispatch }, payload) {
    const type = payload.type
    const id = payload.id

    payload.print = true
    payload.export = false

    delete payload.id
    delete payload.type

    const results = await this.$repositories[type].subPrint(id, payload)
    if (hasOwnProp(results, 'error')) {
      dispatch('set_snack', results)
      return false
    } else {
      commit('SET_PRINT_PATH', results.path)
    }
  },
  async subListExport ({ commit, state, getters }, payload) {
    const type = payload.type
    const id = payload.id

    payload.export = true
    payload.print = false

    delete payload.type
    delete payload.id

    const results = await this.$repositories[type].subExport(id, payload)
    if (hasOwnProp(results, 'error')) {
      return
    }
    commit('SET_PRINT_PATH', results.path)
  },
  async showPrint ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id

    payload.print = true
    payload.export = false

    delete payload.id

    const results = await this.$repositories[state.type].showPrint(id, payload)
    if (hasOwnProp(results, 'error')) {
      dispatch('set_snack', results)
      return false
    } else {
      commit('SET_PRINT_PATH', results.data.path)
    }
  },
  async showExport ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id

    payload.print = false
    payload.export = true

    delete payload.id

    const results = await this.$repositories[state.type].showPrint(id, payload)
    if (hasOwnProp(results, 'error')) {
      dispatch('set_snack', results)
      return false
    } else {
      commit('SET_PRINT_PATH', results.data.path)
    }
  },
  async showExportMerge ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id

    payload.print = false
    payload.export = true
    payload.exporter_model = 'App\\Exports\\SingleWithRelsExport'

    delete payload.id

    const results = await this.$repositories[state.type].showPrint(id, payload)
    if (hasOwnProp(results, 'error')) {
      dispatch('set_snack', results)
      return false
    } else {
      commit('SET_PRINT_PATH', results.data.path)
    }
  },
  async search ({ commit, state, getters, dispatch }, params) {
    let results = {}

    if (navigator.onLine) {
      results = await this.$repositories[state.type].index(params)
    } else {
      results = await localForage.getItem(`state.${state.type}`)
      if (!results || !hasOwnProp(results, 'data')) {
        results = { data: [] }
      }
    }

    if (hasOwnProp(results, 'error')) {
      return
    }

    results.data.map(item => item.id)

    const { entities } = normalize(results.data, [
      this.$relationships[state.type]
    ])

    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )
  }
}
