
import { getComponent } from './FormFieldMap'
import { getRule } from './FormFieldRules'
export default {
  props: {
    value: {
      type: [Object, Array, String, Number, Boolean],
      default: () => ({})
    },
    schema: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    generatedRules () {
      return this.schema.options.rules.map((rule) => {
        return getRule(rule)
      })
    }
    // generatedFormRender () {
    //   const schema = this.schema
    //   return Object.proptotype.hasOwnProperty.call(schema, 'conditionalRender') ? this.checkConditional(schema.conditionalRender) : true
    // }
  },
  methods: {
    mapComponent (type) {
      return getComponent(type) ? getComponent(type) : type
    },
    emitUp (emits) {
      this.$emit('emitUp', emits)
    }
  }

}
